import Vue from 'vue'
import App from './App.vue'

import router from "./router";
import store from '@/store/store'
import ElementUI from "element-ui";
// 自定义element ui主题
import "./style/element-ui-style/index.css";
import http from './utils/http'
import api from './utils/api'
import utils from './utils/utils'
// 公共css
import "./style/index.css";


Vue.prototype.http = http;
Vue.prototype.utils = utils;
Vue.prototype.api = api;
Vue.prototype.$store = store;
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
