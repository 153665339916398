import axios from "axios";
import router from "../router";
const baseURL = process.env.VUE_APP_BASE_URL; // 测试地址
// const baseURL = ''; // 测试地址
const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000
  // headers: { 'X-Custom-Header': 'foobar' }
});
console.log('process>>>', process.env.VUE_APP_BASE_URL);
console.log('baseURL>>>', process.env);
// 添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // const token = localStorage.getItem("token");
    // config.headers["Authorization"] = token;
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
var errMsgControl = true;
instance.interceptors.response.use(
  function(response) {
    // instance// 对响应数据做点什么
    var code = response.data.code;
    if (code == 210 && errMsgControl) {
      errMsgControl = false;
      router.push('/login').then(() =>  errMsgControl = true)
    }
    return response.data;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

const post = (url, data, contentType = "application/json") => {
  return instance({
    url: url,
    // source 1 app 2 pc
    // data: {source: 2, ...data},
    data: data,
    method: "post",
    headers: {
      "Content-Type": contentType
    }
  });
};

const get = (url, params) => {
  return instance({
    url: url,
    // params: {source: 2, params},
    params: params,
    method: "get"
  });
};

const del = (url, data) => {
  return instance({
    url: url,
    // data: {source: 2, ...data},
    data: data,
    method: "delete"
  });
};

const put = (url, data) => {
  return instance({
    url: url,
    // data: {source: 2, ...data},
    data: data,
    method: "put"
  });
};

export default { baseURL, post, get, del, put };
