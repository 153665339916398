export default {


    //直接回到页面顶部
    toPageTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },


    //判断是否为空
    isEmpty(val) {
        if (val == "" || val == null || val == undefined || val == " ")
            return true;
        return false;
    },

    //倒计时
    countDown(obj, num) {
        if (num == 1) {
            var Timer = setInterval(() => {
                if (obj.code_time > 0) {
                    obj.code_time--;
                    obj.code_text = `${obj.code_time}秒后获取`;
                    obj.code_flag = false;
                } else {
                    obj.code_flag = true;
                    obj.code_time = 60;
                    obj.code_text = `重新获取`;
                    clearInterval(Timer);
                }
            }, 1000)
        } else {
            var Timer = setInterval(() => {
                if (obj.re_code_time > 0) {
                    obj.re_code_time--;
                    obj.re_code_text = `${obj.re_code_time}秒后获取`;
                    obj.re_code_flag = false;
                } else {
                    obj.re_code_flag = true;
                    obj.re_code_time = 60;
                    obj.re_code_text = `重新获取`;
                    clearInterval(Timer);
                }
            }, 1000)
        }

    },


    /* 是否手机号码*/
    isMobile(value) {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (value == '' || value == undefined || value == null) {
            return false;
        } else {
            if (!reg.test(value)) {
                return false;
            } else {
                return true;
            }
        }
    },


    //时间戳  转 年月日
    add0(m) {
        return m < 10 ? '0' + m : m
    },
    format(timestamp, type) {
        //timestamp是整数，否则要parseInt转换
        var time = new Date(timestamp);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        if (type == 'yyyy-mm-dd') {
            return y + '.' + this.add0(m) + '.' + this.add0(d);
        } else if (type == 'mm-dd') {
            return this.add0(m) + '.' + this.add0(d);
        } else if (type == 'yyyy-mm-dd/hh:mm:ss') {
            return y + '.' + this.add0(m) + '.' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm) + ':' + this.add0(s);
        }
    },

    //获取链接上参数
    getParam(variable) {
        var query = window.location.search.substring(1);
        console.log("param：" + query);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return (false);
    },

    //秒 转 天时分秒
    getDateTime(time) {
        let d = parseInt(time / 60 / 60 / 24)
        d = this.add0(d);
        let h = parseInt(time / 60 / 60 % 24)
        h = this.add0(h);
        let m = parseInt(time / 60 % 60)
        m = this.add0(m);
        let s = parseInt(time % 60)
        s = this.add0(s);
        return [d, h, m, s];
    },


    //判断是否为ios
    isIOS() {
        var isIphone = navigator.userAgent.includes('iPhone');
        var isIpad = navigator.userAgent.includes('iPad');
        return isIphone || isIpad;
    },

}
