var api = {
  getProvince: '/app/store/province', // 获取省份
  upload: '/app/Themes/upload', // 上传

  authIndex: '/app/register/index', // 我的认证信息
  sub_register: '/app/register/sub_register', // 提交/修改店铺认证

  login: '/app/login/login', // 登录
  get_wx_info: '/app/login/get_wx_info', // 微信登录解析code
  three_login: '/app/login/three_login', // 三方登录/绑定三方
  bang_mobile: '/app/login/bang_mobile', // 微信登录绑定手机
  yzm_login: '/app/login/yzm_login', // 验证码登录
  sendCode: '/app/themes/sendCode', // 发送验证码
  reg: '/app/login/reg', // 注册
  
  clear_cache: '/app/Index/clear_cache', // 清除缓存

  // 首页
  getHome: '/app/Shop/index', // 首页
  news_detail: '/app/Message/news_detail', // 首页

  // 订单管理
  order_shop_list: '/app/cart/order_shop_list', // 订单列表--商家
  order_details: '/app/cart/order_details', // 订单详情
  order_after_details: '/app/After/detail', // 售后订单详情
  order_comment: '/app/cart/order_comment', // 查看订单评价
  set_status_shop: '/app/cart/set_status_shop', // 设置订单状态--商家 1修改金额, 3发货，10收货  11 删除订单
  order_wuliu: '/app/cart/sel_kd', // 物流查询
  set_status: '/app/order/set_status', // 取消/收货/删除订单
  get_orderChat: '/app/cart/get_orderChat', // 获取退款原因.快递列表
  check_after: '/app/After/check_after', // 售后操作--商家
  shop_address_list: '/app/Address/address_list', // 获取商家的收货地址
  after_pass_info: '/app/After/pass_info', // 平台介入，详细信息
  after_order_pass: '/app/After/order_pass', // 平台介入，上传凭证

  auth_info: '/app/themes/auth', // 1用户协议，2隐私政策，3服务协议。4免责说明，5关于我们,7资质说明
  help_center: '/app/themes/help', // 帮助中心
  feedback: '/app/user/feedback', // 提交意见反馈
  fb_category: '/app/user/fb_category', // 意见反馈分类
  feedback_list: '/app/user/feedback_list', // 我的反馈列表


  // 商品管理
  products: '/app/products/products', // 商品列表
  product_category: '/app/products/product_category', // 获取商品分类
  set_product: '/app/products/set_product', // 商品上下架/删除/推荐
  shop_productsDetails: '/app/products/shop_productsDetails', // 商品详情
  create_category: '/app/products/create_category', // 添加/修改二级商品分类
  getYunModel: '/app/Yun/get_list', // 运费模板列表
  add_products: '/app/products/add_products', // 发布/修改商品
  add_yun_temp: '/app/Yun/add', // 添加/修改运费模板
  del_yun_temp: '/app/Yun/del', // 删除运费模板
  bulk_record: '/app/Approve/bulk_record', // 批发价浏览记录

  // 消息中心 -> 系统通知
  msg_list: '/app/Message/msg_list',

  // 消息中心 -> 用户接待
  gateway_post: '/app/chat/gateway_post', // websocket 建立绑定接口
  send_msg: '/app/chat/send_msg', // 发送消息
  send_msgPost: '/app/Chat/send_msgPost', // 发送消息,备用 Socket连不上时用
  msg_index: '/app/Message/msg_index', // 消息首页/用户消息列表
  get_chatlist: '/app/Chat/get_chatlist', // 获取聊天室里的记录
  check_chat: '/app/Chat/check_chat', // 聊天消息置顶/收藏/删除
  fast_reply: '/app/Message/reply', // 我的快捷回复列表
  set_reply: '/app/Message/set_reply', // 添加/修改/删除快捷回复
  
  push_details: '/app/Message/push_details', // 后台推送详细详情

  // 个人中心 -> 收货地址
  zone_list: '/app/Address/zone_list', // 获取省市
  add_address: '/app/Address/add_address', // 添加编辑收货地址
  address_list: '/app/Address/address_list', // 获取商家的收货地址
  delete_address: '/app/Address/delete_address', // 删除地址
  address_details: '/app/Address/address_details', // 地址详情
  address_default: '/app/Address/address_default', // 设置默认地址

  // 个人中心 -> 店铺管理
  my_shop: '/app/Shop/my_shop', // 店铺管理，店铺信息
  set_shop: '/app/Shop/set_shop', // 修改店铺信息

  // 个人中心 -> 系统设置
  get_userInfo: '/app/User/get_userInfo', // 获取的个人信息（通用）
  edit_user: '/app/User/edit_user', // 编辑个人资料
  check_account: '/app/User/check_account', // 验证账号
  set_pwd: '/app/User/set_pwd', // 设置密码
  forget_pwd: '/app/login/forget_pwd', // 找回密码
};

export default api;
