/**
 * 接着我们继续延伸约定，组件不允许直接变更属于 store 实例的 state，而应执行 action 来分发 (dispatch) / 事件通知 store 去改变，
 * 我们最终达成了 Flux 架构。这样约定的好处是，我们能够记录所有 store 中发生的 state 变更，
 * 同时实现能做到记录变更、保存状态快照、历史回滚/时光旅行的先进的调试工具
 * 
 * 在组件中：
 *  data(){
 *      return {
 *          privateState: {},
 *          sharedState: store.state, // .vue 引入方法，此处必须这样写，如果写成sharedState: store.state.message则不能同步更新
 *          // sharedState: this.$store.state // 挂载到 vue 实例时引用方法
 *      }
 *  }
 */
// 一个简单的公共状态管理
// 因为此项目使用vuex过于冗余
const store = {
  debug: true,
  state: {
    isLogin: false, // 是否在登录页面
    defaultMenuActive: '1', // 菜单默认选中
    isAuth: 0, // 是否认证页面 0 不是, 1 是
    userinfo: {

    }, // 用户信息
    uid: '',
    token: '',

    systemMsgNum: 0, // 系统消息未读数量
    dealLogisticsNum: 0, // 物流消息未读数量
    userChatNum: 0, // 聊天未读消息数量

  },
  /**
   * 修改是否在登录页面
   * @param {Boolean} value 
   */
  setIsLoginAction(value) {
    if (this.debug) console.log('setIsLoginAction triggered with', value);
    this.state.isLogin = value;
  },
  // 修改菜单默认选中
  setMenuActive(value) {
    if (this.debug) console.log('setMenuActive triggered with', value);
    this.state.defaultMenuActive = value;
  },
  // 修改商户是否在认证页
  setIsAuthActive(value) {
    if (this.debug) console.log('setIsAuthActive triggered with', value);
    this.state.isAuth = value;
  },
  // 修改用户信息
  setUserinfoActive(value) {
    if (this.debug) console.log('setUserinfoActive triggered with', value);
    this.state.userinfo = value || {};
  },
  // 修改用户uid
  setUid(value) {
    if (this.debug) console.log('setUidActive triggered with', value);
    this.state.uid = value || '';
  },
  // 修改用户token
  setToken(value) {
    if (this.debug) console.log('setTokenActive triggered with', value);
    this.state.token = value || '';
  },
  // 修改聊天消息未读数量
  setUserChatNum(value) {
    if (this.debug) console.log('setUserChatNum triggered with', value);
    this.state.userChatNum = value || '';
  },
  // 修改系统消息未读数量
  setSystemMsgNum(value) {
    if (this.debug) console.log('setSystemMsgNum triggered with', value);
    this.state.systemMsgNum = value || '';
  },
  // 修改物流消息未读数量
  setDealLogisticsNum(value) {
    if (this.debug) console.log('setDealLogisticsNum triggered with', value);
    this.state.dealLogisticsNum = value || '';
  },
}

export default store
