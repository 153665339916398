<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  font-family: 'Microsoft YaHei';
}

.spec-img-wrap {
  min-width: 141px !important;
  padding: 0 !important;
  line-height: 0 !important;
}
</style>
