import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'viewIndex',
      component: () => import("@/pages/index"),
      redirect: 'login',
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import("@/pages/login/index"),
        },
        // 首页
        {
          path: '/index',
          name: 'index',
          component: () => import("@/pages/home/home"),
        },
        // 资质认证
        {
          path: '/auth',
          name: 'auth',
          component: () => import("@/pages/auth/index"),
        },
        // 商品管理
        {
          path: '/goodsManage',
          name: 'goodsManage',
          component: () => import("@/pages/goodsManage"),
        },
        // 商品管理 -> 发布商品 or 编辑商品
        {
          path: '/goodsManage/addOrEidt',
          name: 'addOrEidt',
          component: () => import("@/pages/goodsManage/addOrEidt"),
        },
        // 商品管理 -> 详情
        {
          path: '/goodsManage/detail',
          name: 'goodsManageDetail',
          component: () => import("@/pages/goodsManage/detail"),
        },
        // 订单管理
        {
          path: '/orderManage',
          name: 'orderManage',
          component: () => import("@/pages/orderManage/index"),
        },
        // 订单管理 -> 详情
        {
          path: '/orderManage/detail',
          name: 'orderDetail',
          component: () => import("@/pages/orderManage/detail"),
        },
        // 消息管理 -> 系统通知
        {
          path: '/msgManage/systemMsg',
          name: 'systemMsg',
          component: () => import("@/pages/msgManage/systemMsg"),
        },
        // 消息管理 -> 交易物流
        {
          path: '/msgManage/dealLogistics',
          name: 'dealLogistics',
          component: () => import("@/pages/msgManage/dealLogistics"),
        },
        // 消息管理 -> 用户接待
        {
          path: '/msgManage/userReception',
          name: 'userReception',
          component: () => import("@/pages/msgManage/userReception"),
        },
        // 个人中心 -> 资质认证
        {
          path: '/myCenter/ziZhiAuth',
          name: 'ziZhiAuth',
          component: () => import("@/pages/myCenter/ziZhiAuth/auth"),
        },
        // 个人中心 -> 资质认证 -> 重新认证
        {
          path: '/myCenter/ziZhiAuth/afreshAuth',
          name: 'afreshAuth',
          component: () => import("@/pages/myCenter/ziZhiAuth/afreshAuth"),
        },
        // 个人中心 -> 店铺管理
        {
          path: '/myCenter/shopManage',
          name: 'shopManage',
          component: () => import("@/pages/myCenter/shopManage/index"),
        },
        // 个人中心 -> 收货地址
        {
          path: '/myCenter/shouHuoAddress',
          name: 'shouHuoAddress',
          component: () => import("@/pages/myCenter/shouHuoAddress/index"),
        },
        // 个人中心 -> 收货地址 -> 新增（编辑）地址
        {
          path: '/myCenter/shouHuoAddress/addOrEdit',
          name: 'addOrEditAddress',
          component: () => import("@/pages/myCenter/shouHuoAddress/addOrEdit"),
        },
        // 个人中心 -> 帮助中心
        {
          path: '/myCenter/helpCenter',
          name: 'helpCenter',
          component: () => import("@/pages/myCenter/helpCenter/index"),
        },
        // 个人中心 -> 关于我们
        {
          path: '/myCenter/aboutUs',
          name: 'aboutUs',
          component: () => import("@/pages/myCenter/aboutUs/index"),
        },
        // 个人中心 -> 意见反馈
        {
          path: '/myCenter/feedback',
          name: 'feedback',
          component: () => import("@/pages/myCenter/feedback/index"),
        },
        // 个人中心 -> 意见反馈
        {
          path: '/myCenter/feedback/myFeedback',
          name: 'myFeedback',
          component: () => import("@/pages/myCenter/feedback/myFeedback"),
        },
        // 个人中心 -> 系统设置
        {
          path: '/myCenter/systemSet',
          name: 'systemSet',
          component: () => import("@/pages/myCenter/systemSet/index"),
        },
      ]
    },
  ]
})
